import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Loading from "../../components/Loading";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ConfirmPasswordChange = () => {

  const { username } = useParams();
  const { currentPassword } = useParams();
  const { newPassword } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [openToast, setOpenToast] = React.useState(false);
  const [toastSeverity, setToastSeverity] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  useEffect(async () => {
    console.log(username, 'username')
    console.log(currentPassword, 'currentPassword')
    console.log(newPassword, 'NewPassword')
    const info = { userName: atob(username), currentPassword: atob(currentPassword), newPassword: atob(newPassword) };
    try {
      const response = await axios.put(process.env.REACT_APP_API + "authentication/updatePassword", info);
      sessionStorage.removeItem("_auth");
      sessionStorage.removeItem("userName");
      setIsLoading(false)
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <div className="not-found-page-container">
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      {isLoading && (<Loading />)}
      <div className="not-found-container">
        <span className="not-found-heading">Password Changed!!!</span>
        <span className="not-found-sub-heading">Admin password has been successfully changed</span>
        <span className="not-found-desc">You will be redirected to the login page in a few seconds</span>
      </div>
    </div>
  );
};

export default ConfirmPasswordChange;
