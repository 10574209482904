import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useParams } from "react-router";
import { format } from "date-fns";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loading from "../../components/Loading";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadingLight from "../../components/LoadingLight";
import axios from "axios";
import Sidebar from "../../components/Sidebar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditLocation = () => {
  const { id } = useParams();

  const [openToast, setOpenToast] = React.useState(false);
  const [toastSeverity, setToastSeverity] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("_auth");
    if (token && token != null) {
      getLocationDetails();
    } else {
      navigate("/");
    }
  }, []);

  const navigate = useNavigate();

  const [locationDetails, setLocationDetails] = useState(null);
  const imageFileInputRef = useRef(null);

  const getLocationDetails = async () => {

    try {
      const response = await axios.get(process.env.REACT_APP_API + "Locations/locationDetails/getDetails/" + id);
      let formatDate = format(
        new Date(response.data.lastUpdated),
        "dd/MM/yyyy HH:mm:ss"
      );
      response.data.lastUpdated = formatDate;
      setLocationDetails(response.data);
      setNameInEnglish(response.data.nameInEnglish);
      setNameInSinhala(response.data.nameInSinhala);
      setNameInTamil(response.data.nameInTamil);
      if (response.data.hotspotArray) {
        setHotspotArray(JSON.parse(response.data.hotspotArray));
      } else {
        setHotspotArray([])
      }
      setDescriptionInEnglish(response.data.descriptionInEnglish);
      setDescriptionInSinhala(response.data.descriptionInSinhala);
      setDescriptionInTamil(response.data.descriptionInTamil);
      setImageUrl(response.data.locationImage)
      setIsLoading(false)
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const [isEnglishNameDisabled, setIsEnglishNameDisabled] = useState(true);
  const [isSinhalaNameDisabled, setIsSinhalaNameDisabled] = useState(true);
  const [isTamilNameDisabled, setIsTamilNameDisabled] = useState(true);

  const [name, setName] = useState("");
  const [nameInEnglish, setNameInEnglish] = useState("");
  const [nameInSinhala, setNameInSinhala] = useState("");
  const [nameInTamil, setNameInTamil] = useState("");
  const [hotspotArray, setHotspotArray] = useState([]);
  const [descriptionInEnglish, setDescriptionInEnglish] = useState("");
  const [descriptionInSinhala, setDescriptionInSinhala] = useState("");
  const [descriptionInTamil, setDescriptionInTamil] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoadingSave(true);
    if (name.length > 0) {

      hotspotArray.forEach(x => {
        x.disableHotspotTitleEnglish = true;
        x.disableHotspotTitleSinhala = true;
        x.disableHotspotTitleTamil = true;
        x.disableHotspotDescriptionEnglish = true;
        x.disableHotspotDescriptionSinhala = true;
        x.disableHotspotDescriptionTamil = true;
        x.changeHotspotVoiceEnglish = false;
        x.changeHotspotVoiceSinhala = false;
        x.changeHotspotVoiceTamil = false;
        x.changeHotspotImage = false;
        x.changeHotspotIframe = false;
      })

      const info = {
        locationDetailId: locationDetails.locationDetailId,
        location: locationDetails.location,
        nameInEnglish: nameInEnglish,
        nameInSinhala: nameInSinhala,
        nameInTamil: nameInTamil,
        hotspotArray: JSON.stringify(hotspotArray),
        descriptionInEnglish: descriptionInEnglish,
        descriptionInSinhala: descriptionInSinhala,
        descriptionInTamil: descriptionInTamil,
        locationImageName: locationDetails.locationImageName,
        locationImage: locationDetails.locationImage,
        lastUpdatedBy: name,
        lastUpdated: new Date(),
      };
      console.log(info, 'info');
      const updateLocation = async () => {
        try {
          const response = await axios.put(process.env.REACT_APP_API + "Locations/locationDetails/update", info);
          console.log(response, 'update');

          if (imageChange) {
            const formData = new FormData();
            formData.append("file", selectedImage);
            formData.append("upload_preset", "r3r9hnen")
            const postImage = async () => {
              try {
                const response = await axios.post("https://api.cloudinary.com/v1_1/ditejyeth/upload", formData);
                console.log(response);
                const locationImageInfo = {
                  locationDetailId: locationDetails.locationDetailId,
                  locationImageName: response.data.original_filename,
                  locationImageUrl: response.data.url,
                  lastUpdatedBy: name,
                  lastUpdated: new Date(),
                };
                const updateLocationImage = async () => {
                  try {
                    const response = await axios.put(process.env.REACT_APP_API + "Locations/locationDetailsUpdateImage", locationImageInfo);
                    console.log(response, 'updateimage');
                    setIsLoadingSave(false);
                    setToastSeverity("success");
                    setToastMessage("Location image updated successfully");
                    setOpenToast(true);
                  } catch (error) {
                    console.error(error);
                    setIsLoadingSave(false);
                    console.log("There was an error!", error);
                  }
                }
                updateLocationImage();
              } catch (error) {
                console.error(error);
                setToastSeverity("error");
                setToastMessage(error.response.data.error.message);
                setOpenToast(true);
              }
            }
            postImage();
          }
          setIsLoadingSave(false);
          setToastSeverity("success");
          setToastMessage("Location updated successfully");
          setOpenToast(true);
        } catch (error) {
          setIsLoadingSave(false);
          console.log("There was an error!", error);
        }
      }
      updateLocation();

    } else {
      setIsLoadingSave(false);
      setToastSeverity("error")
      setToastMessage("Name is required");
      setOpenToast(true);
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  const [imageChange, setImageChange] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);

  const handleFileClick = () => {
    imageFileInputRef.current.click();
  };

  const onFileChange = (e) => {
    setSelectedImage(e.target.files[0])
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setImageChange(true);
  }

  const onEnglishAudioFileChange = (e, el) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "r3r9hnen")
    const postEnglishAudio = async () => {
      try {
        const response = await axios.post("https://api.cloudinary.com/v1_1/ditejyeth/upload", formData);
        hotspotArray[el.index - 1].hotspotVoiceEnglishName = response.data.original_filename;
        hotspotArray[el.index - 1].hotspotVoiceEnglish = response.data.url;
        hotspotArray[el.index - 1].changeHotspotVoiceEnglish = false;
        setHotspotArray([...hotspotArray]);
      } catch (error) {
        console.error(error);
      }
    }
    postEnglishAudio();
  }

  const onSinhalaAudioFileChange = (e, el) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "r3r9hnen")
    const postSinhalaAudio = async () => {
      try {
        const response = await axios.post("https://api.cloudinary.com/v1_1/ditejyeth/upload", formData);
        hotspotArray[el.index - 1].hotspotVoiceSinhalaName = response.data.original_filename;
        hotspotArray[el.index - 1].hotspotVoiceSinhala = response.data.url;
        hotspotArray[el.index - 1].changeHotspotVoiceSinhala = false;
        setHotspotArray([...hotspotArray]);
      } catch (error) {
        console.error(error);
      }
    }
    postSinhalaAudio();
  }

  const onTamilAudioFileChange = (e, el) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "r3r9hnen")
    const postTamilAudio = async () => {
      try {
        const response = await axios.post("https://api.cloudinary.com/v1_1/ditejyeth/upload", formData);
        hotspotArray[el.index - 1].hotspotVoiceTamilName = response.data.original_filename;
        hotspotArray[el.index - 1].hotspotVoiceTamil = response.data.url;
        hotspotArray[el.index - 1].changeHotspotVoiceTamil = false;
        setHotspotArray([...hotspotArray]);
      } catch (error) {
        console.error(error);
      }
    }
    postTamilAudio();
  }

  const onHotspotFileChange = (e, el) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "r3r9hnen")
    const postImageHotspot = async () => {
      try {
        const response = await axios.post("https://api.cloudinary.com/v1_1/ditejyeth/upload", formData);
        hotspotArray[el.index - 1].hotspotImageName = response.data.original_filename;
        hotspotArray[el.index - 1].hotspotImage = response.data.url;
        hotspotArray[el.index - 1].changeHotspotImage = false;
        setHotspotArray([...hotspotArray]);
      } catch (error) {
        console.error(error);
      }
    }
    postImageHotspot();
  }


  return (
    <div className="page-container">
      {isLoading && (<Loading />)}
      {isLoadingSave && (<LoadingLight />)}
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      <Header />
      <div className="d-flex w-100 h-100">
        <Sidebar />
        <div className="d-flex flex-column w-100 h-100">
          <div className="page-inner w-100">
            <div className="page-title-row tab-col-mob">
              <div className="d-flex flex-column align-items-start center-col-mob">
                <span className="page-title">
                  {locationDetails?.nameInEnglish}
                </span>
                <span className="page-subtitle">{locationDetails?.location}</span>
              </div>
              <div className="flex-column-end center-col-mob">
                <span className="edited-details">
                  Last Edited : <b>{locationDetails?.lastUpdatedBy}</b>
                </span>
                <span className="edited-details">
                  {locationDetails?.lastUpdated}
                </span>
              </div>
            </div>

            <Tabs
              defaultActiveKey="names" className="mt-3 location-tabs"
            >
              <Tab eventKey="names" title="Names">
                <Form className="d-flex flex-column align-items-start w-100 mt-3">
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                    controlId="formName"
                  >
                    <Form.Label className="form-label me-2">
                      Name (English) :
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        className="form-edit-input"
                        type="text"
                        value={nameInEnglish}
                        placeholder="Enter name"
                        disabled={isEnglishNameDisabled}
                        onChange={(e) => setNameInEnglish(e.target.value)}
                      />
                      {isEnglishNameDisabled && (
                        <InputGroup.Text
                          className="input-addon"
                          onClick={() => {
                            setIsEnglishNameDisabled(!isEnglishNameDisabled);
                          }}
                        >
                          Change
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                    controlId="formName"
                  >
                    <Form.Label className="form-label me-2">
                      Name (Sinhala) :
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        className="form-edit-input"
                        type="text"
                        value={nameInSinhala}
                        placeholder="Enter name"
                        disabled={isSinhalaNameDisabled}
                        onChange={(e) => setNameInSinhala(e.target.value)}
                      />
                      {isSinhalaNameDisabled && (
                        <InputGroup.Text
                          className="input-addon"
                          onClick={() => {
                            setIsSinhalaNameDisabled(!isSinhalaNameDisabled);
                          }}
                        >
                          Change
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                    controlId="formName"
                  >
                    <Form.Label className="form-label me-2">
                      Name (Tamil) :
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        className="form-edit-input"
                        type="text"
                        value={nameInTamil}
                        placeholder="Enter name"
                        disabled={isTamilNameDisabled}
                        onChange={(e) => setNameInTamil(e.target.value)}
                      />
                      {isTamilNameDisabled && (
                        <InputGroup.Text
                          className="input-addon"
                          onClick={() => {
                            setIsTamilNameDisabled(!isTamilNameDisabled);
                          }}
                        >
                          Change
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Tab>
              <Tab eventKey="hotspotTitles" title="Hotspots">

                {hotspotArray && (
                  <div>
                    {hotspotArray.map((el, i) => (
                      <div className="hotspot-container" key={el.index} >
                        <Form className="d-flex flex-column align-items-start w-100 mt-3">
                          <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                            <span className="hotspot-title">Hotspot {el.index}</span>
                            <div className="d-flex align-items-center">
                              <Form.Check
                                type="checkbox" className="hotspot-form-checkbox-label me-5"
                                id="disableHotspot" defaultChecked={el.disable}
                                label="Disable Hotspot"
                                onChange={e => {
                                  hotspotArray[i].disable = !hotspotArray[i].disable;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              />
                              <Form.Check
                                type="checkbox" className="hotspot-form-checkbox-label"
                                id="disableHotspotIframe" defaultChecked={el.disableHotspotIframe}
                                label="Disable Iframe Video"
                                onChange={e => {
                                  hotspotArray[i].disableHotspotIframe = !hotspotArray[i].disableHotspotIframe;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              />
                            </div>
                          </div>

                          <Form.Group
                            className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <Form.Label className="hotspot-form-label hotspot-form-label-mr">
                              Hotspot {el.index} Title (English) :
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-edit-input"
                                type="text"
                                value={el.hotspotTitleEnglish}
                                placeholder="Enter name"
                                disabled={el.disableHotspotTitleEnglish}
                                onChange={e => {
                                  hotspotArray[i].hotspotTitleEnglish = e.target.value;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              />
                              {el.disableHotspotTitleEnglish && (
                                <InputGroup.Text
                                  className="input-addon"
                                  onClick={() => {
                                    hotspotArray[i].disableHotspotTitleEnglish = !hotspotArray[i].disableHotspotTitleEnglish;
                                    setHotspotArray([...hotspotArray]);
                                  }}
                                >
                                  Change
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <Form.Label className="hotspot-form-label hotspot-form-label-mr">
                              Hotspot {el.index} Title (Sinhala) :
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-edit-input"
                                type="text"
                                value={el.hotspotTitleSinhala}
                                placeholder="Enter name"
                                disabled={el.disableHotspotTitleSinhala}
                                onChange={e => {
                                  hotspotArray[i].hotspotTitleSinhala = e.target.value;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              />
                              {el.disableHotspotTitleSinhala && (
                                <InputGroup.Text
                                  className="input-addon"
                                  onClick={() => {
                                    hotspotArray[i].disableHotspotTitleSinhala = !hotspotArray[i].disableHotspotTitleSinhala;
                                    setHotspotArray([...hotspotArray]);
                                  }}
                                >
                                  Change
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <Form.Label className="hotspot-form-label hotspot-form-label-mr">
                              Hotspot {el.index} Title (Tamil) :
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-edit-input"
                                type="text"
                                value={el.hotspotTitleTamil}
                                placeholder="Enter name"
                                disabled={el.disableHotspotTitleTamil}
                                onChange={e => {
                                  hotspotArray[i].hotspotTitleTamil = e.target.value;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              />
                              {el.disableHotspotTitleTamil && (
                                <InputGroup.Text
                                  className="input-addon"
                                  onClick={() => {
                                    hotspotArray[i].disableHotspotTitleTamil = !hotspotArray[i].disableHotspotTitleTamil;
                                    setHotspotArray([...hotspotArray]);
                                  }}
                                >
                                  Change
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            className="d-flex align-items-start w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <div className="d-flex flex-column align-items-start me-2">
                              <Form.Label className="hotspot-form-label">
                                Hostpsot {el.index} Description (English) :
                              </Form.Label>
                              <span
                                className="change-txt-btn"
                                onClick={() => {
                                  hotspotArray[i].disableHotspotDescriptionEnglish = !hotspotArray[i].disableHotspotDescriptionEnglish;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              >
                                Change
                              </span>
                            </div>

                            <Form.Control
                              className=""
                              as="textarea"
                              style={{ height: "100px" }}
                              type="text"
                              value={el.hotspotDescriptionEnglish}
                              disabled={el.disableHotspotDescriptionEnglish}
                              placeholder="Description (English)"
                              onChange={e => {
                                hotspotArray[i].hotspotDescriptionEnglish = e.target.value;
                                hotspotArray[i].disableHotspotDescriptionEnglish = false;
                                setHotspotArray([...hotspotArray]);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <div className="d-flex flex-column align-items-start me-2">
                              <Form.Label className="hotspot-form-label">
                                Hostpsot {el.index} Description (Sinhala) :
                              </Form.Label>
                              <span
                                className="change-txt-btn"
                                onClick={() => {
                                  hotspotArray[i].disableHotspotDescriptionSinhala = !hotspotArray[i].disableHotspotDescriptionSinhala;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              >
                                Change
                              </span>
                            </div>

                            <Form.Control
                              className=""
                              as="textarea"
                              style={{ height: "100px" }}
                              type="text"
                              value={el.hotspotDescriptionSinhala}
                              disabled={el.disableHotspotDescriptionSinhala}
                              placeholder="Description (Sinhala)"
                              onChange={e => {
                                hotspotArray[i].hotspotDescriptionSinhala = e.target.value;
                                setHotspotArray([...hotspotArray]);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <div className="d-flex flex-column align-items-start me-2">
                              <Form.Label className="hotspot-form-label">
                                Hostpsot {el.index} Description (Tamil) :
                              </Form.Label>
                              <span
                                className="change-txt-btn"
                                onClick={() => {
                                  hotspotArray[i].disableHotspotDescriptionTamil = !hotspotArray[i].disableHotspotDescriptionTamil;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              >
                                Change
                              </span>
                            </div>

                            <Form.Control
                              className=""
                              as="textarea"
                              style={{ height: "100px" }}
                              type="text"
                              value={el.hotspotDescriptionTamil}
                              disabled={el.disableHotspotDescriptionTamil}
                              placeholder="Description (Tamil)"
                              onChange={e => {
                                hotspotArray[i].hotspotDescriptionTamil = e.target.value;
                                setHotspotArray([...hotspotArray]);
                              }}
                            />
                          </Form.Group>
                        </Form>
                        <div className="d-flex align-items-center mb-3  mt-3 form-start-col-mob">
                          <span className="hotspot-form-label me-2">Hotspot {el.index} Voice (English) : </span>
                          {!el.changeHotspotVoiceEnglish && (
                            <section className="d-flex align-items-center">
                              {el.hotspotVoiceEnglishName && (
                                <span className="form-value me-3">
                                  {el.hotspotVoiceEnglishName}
                                </span>
                              )}
                              <span className="change-txt-btn" onClick={() => {
                                hotspotArray[i].changeHotspotVoiceEnglish = !hotspotArray[i].changeHotspotVoiceEnglish;
                                setHotspotArray([...hotspotArray]);
                              }}>Change</span>
                            </section>
                          )}
                          {el.changeHotspotVoiceEnglish && (
                            <Form.Control type="file" className="form-control" accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                onEnglishAudioFileChange(e, el);
                              }} />
                          )}
                        </div>
                        <div className="d-flex align-items-center mb-3 form-start-col-mob">
                          <span className="hotspot-form-label me-2">Hotspot {el.index} Voice (Sinhala) : </span>
                          {!el.changeHotspotVoiceSinhala && (
                            <section className="d-flex align-items-center">
                              {el.hotspotVoiceSinhalaName && (
                                <span className="form-value me-3">
                                  {el.hotspotVoiceSinhalaName}
                                </span>
                              )}
                              <span className="change-txt-btn" onClick={() => {
                                hotspotArray[i].changeHotspotVoiceSinhala = !hotspotArray[i].changeHotspotVoiceSinhala;
                                setHotspotArray([...hotspotArray]);
                              }}>Change</span>
                            </section>
                          )}
                          {el.changeHotspotVoiceSinhala && (
                            <Form.Control type="file" className="form-control" accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                onSinhalaAudioFileChange(e, el);
                              }
                              }
                            />
                          )}
                        </div>
                        <div className="d-flex align-items-center mb-3 form-start-col-mob">
                          <span className="hotspot-form-label me-2">Hotspot {el.index} Voice (Tamil) : </span>
                          {!el.changeHotspotVoiceTamil && (
                            <section className="d-flex align-items-center">
                              {el.hotspotVoiceTamilName && (
                                <span className="form-value me-3">
                                  {el.hotspotVoiceTamilName}
                                </span>
                              )}
                              <span className="change-txt-btn" onClick={() => {
                                hotspotArray[i].changeHotspotVoiceTamil = !hotspotArray[i].changeHotspotVoiceTamil;
                                setHotspotArray([...hotspotArray]);
                              }}>Change</span>
                            </section>
                          )}
                          {el.changeHotspotVoiceTamil && (
                            <Form.Control type="file" className="form-control" accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                onTamilAudioFileChange(e, el);
                              }
                              }
                            />
                          )}
                        </div>
                        <div className="d-flex align-items-center mb-3 form-start-col-mob">
                          <span className="hotspot-form-label me-2">Hotspot Image : </span>
                          {!el.changeHotspotImage && (
                            <section className="d-flex align-items-center">
                              {el.hotspotImageName && (
                                <span className="form-value me-3">
                                  {el.hotspotImageName}
                                </span>
                              )}
                              <span className="change-txt-btn" onClick={() => {
                                hotspotArray[i].changeHotspotImage = !hotspotArray[i].changeHotspotImage;
                                setHotspotArray([...hotspotArray]);
                              }}>Change</span>
                            </section>
                          )}
                          {el.changeHotspotImage && (
                            <Form.Control type="file" className="form-control" accept="image/*"
                              onChange={(e) => {
                                onHotspotFileChange(e, el);
                              }
                              }
                            />
                          )}
                        </div>
                        <Form.Group
                            className="d-flex align-items-center w-100 mb-4 form-start-col-mob"
                            controlId="formName"
                          >
                            <Form.Label className="hotspot-form-label hotspot-form-label-mr">
                              Hotspot {el.index} Video Iframe Link :
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-edit-input"
                                type="text"
                                value={el.hotspotIframe}
                                placeholder="Enter Iframe Link"
                                disabled={el.changeHotspotIframe}
                                onChange={e => {
                                  hotspotArray[i].hotspotIframe = e.target.value;
                                  setHotspotArray([...hotspotArray]);
                                }}
                              />
                              {el.changeHotspotIframe && (
                                <InputGroup.Text
                                  className="input-addon"
                                  onClick={() => {
                                    hotspotArray[i].changeHotspotIframe = !hotspotArray[i].changeHotspotIframe;
                                    setHotspotArray([...hotspotArray]);
                                  }}
                                >
                                  Change
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                      </div>
                    ))}
                  </div>
                )}
              </Tab>
              <Tab eventKey="image" title="Image">
                <div className="d-flex align-items-center mb-3 mt-3 form-start-col-mob">
                  <span className="form-label me-2">Main Image : </span>
                  <Form.Control type="file" onChange={(e) => onFileChange(e)} style={{ display: 'none' }}
                    ref={imageFileInputRef} />
                  <span className="form-value me-5">
                    {locationDetails?.locationImageName}
                  </span>
                  <span className="change-txt-btn" onClick={handleFileClick}>Change</span>
                </div>
                <img className="location-image" id="locationImg" src={imageUrl} />
              </Tab>
            </Tabs>

          </div>
          <div className="footer-container">
            <div className="header-row">
              <div className="flex-row-center">
                <WarningIcon className="footer-icon" />
                <span className="footer-details">
                  By saving this, all the previous details will be completely
                  deleted and new changes will be added. You should enter your name
                  before you save.
                </span>
              </div>
              <div className="save-div">
                <Form className="d-flex align-items-center footer-col-mob">
                  <Form.Group
                    className="d-flex align-items-center footer-start-col-mob"
                    controlId="formName"
                  >
                    <Form.Label className="footer-label">Name : </Form.Label>
                    <Form.Control
                      className="save-input"
                      type="text"
                      placeholder="Enter name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    className="save-btn"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default EditLocation;
