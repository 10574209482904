import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loading from "../../components/Loading";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ConfirmChangeEmail = () => {

  const { username } = useParams();
  const { newEmail } = useParams();
  const { password } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [openToast, setOpenToast] = React.useState(false);
  const [toastSeverity, setToastSeverity] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  useEffect(() => {
    changeEmailRequest();
  }, []);

  const changeEmailRequest = async (event) => {
    try {
      const emailPasswordCheckResponse = await axios.get(process.env.REACT_APP_API + "authentication/checkPassword/" + atob(username) + "/" + atob(password));
      var encodedUsername = username;
      var encodedNewEmail = newEmail;
      var encodedPassword = password;
      const redirectUrl = process.env.REACT_APP_URL + "#/change-email/" + encodedUsername + "/" + encodedNewEmail + "/" + encodedPassword;
      console.log(redirectUrl, 'redirectUrl')
      const emailDto = {
        to: atob(newEmail),
        subject: "Change Email Confirmation Email",
        body: `<div><span>Confirmation Link: <a href='${redirectUrl}'>${redirectUrl}</a></span></div>`
      };
      try {
        const response = await axios.post(process.env.REACT_APP_API + "email/sendChangeEmailConfirmation", emailDto);
        setIsLoading(false)
      } catch (error) {
        console.error(error);
        console.log("There was an error!", error);
      }
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
      setToastSeverity("error")
      setToastMessage("Something went wrong");
      setOpenToast(true);
    }
  }

  return (
    <div className="not-found-page-container">
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      {isLoading && (<Loading />)}
      <div className="not-found-container">
        <span className="not-found-heading">Almost done!!!</span>
        <span className="not-found-sub-heading px-5">Another confirmation email has been sent to the new email address. Please click on the link in that mail to finish changing the email address.</span>
      </div>
    </div>
  );
};

export default ConfirmChangeEmail;
