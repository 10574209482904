import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import Pagination from 'react-bootstrap/Pagination';
import Loading from "../../components/Loading";
import axios from "axios";
import Sidebar from "../../components/Sidebar";

const Locations = () => {
  useEffect(() => {
    const token = sessionStorage.getItem("_auth");
    if (token && token != null) {
      getLocationDetails(1, "");
    } else {
      navigate("/");
    }
  }, []);

  const navigate = useNavigate();

  const [locationDetails, setLocationDetails] = useState([]);
  const [paginationItems, setPaginationItems] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const getLocationDetails = async (pageNo, location) => {
    setIsLoading(true);
    setLocationDetails([]);
    try {
      const response = await axios.get(process.env.REACT_APP_API + `Locations/locationDetails/getAllLocations?page=${encodeURIComponent(pageNo)}&location=${encodeURIComponent(location)}`);
      setLocationDetails(response.data.applicationLocationDetail);
      setPaginationItems([]);
      let active = response.data.currentPage;
      let items = [];
      for (let number = 1; number <= response.data.pages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === active} onClick={() => goToPage(number)}>
            {number}
          </Pagination.Item>,
        );
      }
      setPaginationItems(items);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const goToPage = async (number) => {
    console.log(locationSearch)
    await getLocationDetails(number, locationSearch)
  };

  const navigateToEditLocation = (id) => {
    navigate("/edit-location/" + id);
  };

  const [isShowThumbnail, setShowThumbnail] = useState(true);

  const locationsArray = [
    {
      id: 1,
      location: "",
      description: "All Locations",
    },
    {
      id: 2,
      location: "Public Entrance",
      description: "Public Entrance",
    },
    {
      id: 2,
      location: "Exhibits Area",
      description: "Exhibits Area",
    },
    {
      id: 3,
      location: "Copper Door",
      description: "Copper Door",
    },
    {
      id: 4,
      location: "Silver Door",
      description: "Silver Door",
    },
    {
      id: 4,
      location: "Chambers",
      description: "Chambers",
    },
  ];

  const onLocationSelect = async (location) => {
    setLocationSearch(location);
    await getLocationDetails(1, location)
  };

  return (
    <div className="page-container">
      <Header />
      {isLoading && (<Loading />)}
      <div className="d-flex">
        <Sidebar />
        <div className="page-inner w-100">
          <div className="d-flex align-items-center">
            <Form.Select className="w-300" aria-label="Default select example" onChange={(e) => onLocationSelect(e.target.value)}>
              {locationsArray.map((el) => (
                <option key={el.id} value={el.location}>{el.description}</option>
              ))}
            </Form.Select>
            <Form.Check className="ms-auto"
              type="checkbox"
              id="hide-thumbnail"
              label="Hide Thumbnail" onChange={() => {
                setShowThumbnail(!isShowThumbnail);
              }}
            />
            {/* <Form.Control
            className="search-input"
            type="text" value={searchString}
            placeholder="Search Location" onChange={(e) => setSearchString(e.target.value)}
          /> */}
          </div>
          <Table responsive hover className="mt-5 locations-table">
            <thead>
              <tr>
                {isShowThumbnail && (
                  <th>Thumbnail</th>
                )}
                <th className="id-col">ID</th>
                <th className="name-col">Name</th>
                <th className="loc-col">Location</th>
                <th className="desc-col">Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {locationDetails.map((el) => (
                <tr key={el.locationDetailId}>
                  {isShowThumbnail && (
                    <td>
                      <img className="thumbnail-img" src={el.locationImage} />
                    </td>
                  )}
                  <td className="id-col">#{el.locationDetailId}</td>
                  <td className="name-col">{el.nameInEnglish}</td>
                  <td className="loc-col">{el.location}</td>
                  <td className="desc-col">
                    <span className="desc-value">{el.descriptionInEnglish}
                    </span></td>
                  <td>
                    <Button className="edit-btn" variant="contained" onClick={() => navigateToEditLocation(el.locationDetailId)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="d-flex align-items-center justify-content-center">{paginationItems}</Pagination>
        </div>
      </div>

    </div>
  );
};

export default Locations;
