import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Loading from "../../components/Loading";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChangeEmail = () => {

  const { username } = useParams();
  const { newEmail } = useParams();
  const { password } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [openToast, setOpenToast] = React.useState(false);
  const [toastSeverity, setToastSeverity] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  useEffect(() => {
    changeEmail();
  }, []);

  const changeEmail = async (event) => {
    try {
      const emailPasswordCheckResponse = await axios.get(process.env.REACT_APP_API + "authentication/checkPassword/" + atob(username) + "/" + atob(password));
      const info = { userName: atob(username), email: atob(newEmail) };
      try {
        const response = await axios.put(process.env.REACT_APP_API + "authentication/updateEmail", info);
        setIsLoading(false)
        setTimeout(() => {
          navigate("/");
        }, 4000);
      } catch (error) {
        console.error(error);
        console.log("There was an error!", error);
        setToastSeverity("error")
        setToastMessage("Something went wrong");
        setOpenToast(true);
      }
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
      setToastSeverity("error")
      setToastMessage("Something went wrong");
      setOpenToast(true);
    }
  }

  const navigate = useNavigate();

  return (
    <div className="not-found-page-container">
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      {isLoading && (<Loading />)}
      <div className="not-found-container">
        <span className="not-found-heading">Email Changed!!!</span>
        <span className="not-found-sub-heading">Admin email has been successfully changed</span>
        <span className="not-found-desc">You will be redirected to the login page in a few seconds</span>
      </div>
    </div>
  );
};

export default ChangeEmail;
