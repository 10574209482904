import React from "react";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Sidebar = (props) => {

  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/locations");
  };

  const navigateToProfileSettings = () => {
    navigate("/change-details");
  };

  const logout = () => {
    sessionStorage.removeItem("_auth");
    sessionStorage.removeItem("userName");
    navigate("/");
  };

  return (
    <div className="sidebar-container">
      <div className="d-flex flex-column h-100">
        <span className="menu-main">Main Menu</span>
        <hr />
        <div className="d-flex align-items-center mt-3 mb-4 menu-div" onClick={navigateToHome}>
          <LocationOnIcon />
          <span className="menu-name ms-2">Locations</span>
        </div>
        <div className="d-flex align-items-center menu-div mb-4" onClick={navigateToProfileSettings}>
          <AdminPanelSettingsIcon />
          <span className="menu-name ms-2">Settings</span>
        </div>
        <div className="d-flex align-items-center menu-div" onClick={logout}>
          <ExitToAppIcon />
          <span className="menu-name ms-2">Logout</span>
        </div>
        <hr className="mt-auto" />
        <span className="footer-name">Parliament VR Tour Admin Panel</span>
      </div>
    </div>
  );
};

export default Sidebar;
