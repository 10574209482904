import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import WarningIcon from "@mui/icons-material/Warning";
import { ChromePicker } from "react-color";
import axios from "axios";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Sidebar from "../../components/Sidebar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginDetails = () => {

  const [openToast, setOpenToast] = React.useState(false);
  const [toastSeverity, setToastSeverity] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const [lastUpdatedBy, setLastUpdatedBy] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    getLoginDetails();
    getColors();
  }, []);

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      setColorSchemeId(response.data[0].colorSchemeId);
      setPrimaryColorCode(response.data[0].primaryColor);
      setSecondaryColorCode(response.data[0].secondaryColor);
      setLastUpdatedByForColor(response.data[0].lastUpdatedBy);
      let formatDate = format(
        new Date(response.data[0].lastUpdated),
        "dd/MM/yyyy HH:mm:ss"
      );
      setLastUpdatedForColor(formatDate);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const getLoginDetails = async () => {
    const userName = sessionStorage.getItem("userName");
    try {
      const response = await axios.get(process.env.REACT_APP_API + "authentication/getUser/" + userName);
      console.log(response, 'loginresponse')
      setUserDetails(response.data);
      setLastUpdatedBy(response.data.fullName);
      let formatDate = format(
        new Date(response.data.createdOn),
        "dd/MM/yyyy HH:mm:ss"
      );
      setLastUpdated(formatDate);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  }

  const [currentAdminEmail, setCurrentAdminEmail] = useState("");
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [adminEmailChangePassword, setAdminEmailChangePassword] = useState("");
  const [isConfirmEmailChange, setIsConfirmEmailChange] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordChange, setIsConfirmPasswordChange] = useState(false);

  const changeEmailRequest = async (event) => {
    event.preventDefault();
    if (isConfirmEmailChange) {
      if (currentAdminEmail.length > 0 && newAdminEmail.length > 0 && adminEmailChangePassword.length > 0) {
        if (currentAdminEmail === userDetails.email) {
          const emailPasswordCheckResponse = await fetch(process.env.REACT_APP_API + "authentication/checkPassword/" + userDetails.userName + "/" + adminEmailChangePassword);
          try {
            const emailPasswordCheckData = await emailPasswordCheckResponse.json();
            console.log(emailPasswordCheckData, 'passwordCheckData')
            if (emailPasswordCheckData) {
              var encodedUsername = btoa(userDetails.userName);
              var encodedNewEmail = btoa(newAdminEmail);
              var encodedPassword = btoa(adminEmailChangePassword);
              const redirectUrl = process.env.REACT_APP_URL + "#/change-email-confirmation/" + encodedUsername + "/" + encodedNewEmail + "/" + encodedPassword;
              console.log(redirectUrl, 'redirectUrl')
              const emailDto = {
                to: userDetails.email,
                subject: "Change Email Confirmation Email",
                body: `<div><span>Confirmation Link: <a href='${redirectUrl}'>${redirectUrl}</a></span></div>`
              };
              console.log(emailDto, 'emailDto')
              try {
                const response = await axios.post(process.env.REACT_APP_API + "email/sendChangeEmailConfirmation", emailDto);
                setToastSeverity("success")
                setToastMessage("Confirmation email has been sent");
                setOpenToast(true);
              } catch (error) {
                console.error(error);
                console.log("There was an error!", error);
              }
            }
          } catch (err) {
            console.log(err, 'err')
            setToastSeverity("error")
            setToastMessage("Current Password is incorrect");
            setOpenToast(true);
            return err;
          }
        } else {
          setToastSeverity("error")
          setToastMessage("Current email is wrong");
          setOpenToast(true);
        }
      } else {
        setToastSeverity("error")
        setToastMessage("Please fill all fields");
        setOpenToast(true);
      }
    } else {
      setToastSeverity("error")
      setToastMessage("Confirm checkbox is not selected");
      setOpenToast(true);
    }
  }

  const changePasswordRequest = async (event) => {
    event.preventDefault();
    if (isConfirmPasswordChange) {
      if (currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0) {
        var withNoDigits = newPassword.replace(/[0-9]/g, '');
        var upperCaseWords = withNoDigits.replace(/[a-z]/g, '');
        if (upperCaseWords.length > 0) {
          var alphanumeric = newPassword.match("^[a-zA-Z0-9]*$");
          console.log(alphanumeric, 'alphanumeric')
          if (!newPassword.match("^[a-zA-Z0-9]*$")) {
            if (newPassword === confirmPassword) {
              const passwordCheckResponse = await fetch(process.env.REACT_APP_API + "authentication/checkPassword/" + userDetails.userName + "/" + currentPassword);
              try {
                const passwordCheckData = await passwordCheckResponse.json();
                console.log(passwordCheckData, 'passwordCheckData')
                if (passwordCheckData) {
                  var encodedUsername = btoa(userDetails.userName);
                  var encodedCurrentPassword = btoa(currentPassword);
                  var encodedNewPassword = btoa(newPassword);
                  const redirectUrl = process.env.REACT_APP_URL + "#/change-password/" + encodedUsername + "/" + encodedCurrentPassword + "/" + encodedNewPassword;
                  console.log(redirectUrl, 'redirectUrl')
                  const emailDto = {
                    to: userDetails.email,
                    subject: "Change Password Confirmation Email",
                    body: `<div><span>Confirmation Link: <a href='${redirectUrl}'>${redirectUrl}</a></span></div>`
                  };
                  console.log(emailDto, 'emailDto')
                  try {
                    const response = await axios.post(process.env.REACT_APP_API + "email/sendChangePasswordConfirmation", emailDto);
                    setToastSeverity("success")
                    setToastMessage("Confirmation email has been sent");
                    setOpenToast(true);
                  } catch (error) {
                    console.error(error);
                    console.log("There was an error!", error);
                  }
                }
              } catch (err) {
                setToastSeverity("error")
                setToastMessage("Current Password is incorrect");
                setOpenToast(true);
                return err;
              }
            } else {
              setToastSeverity("error")
              setToastMessage("New password and confirm password do not match");
              setOpenToast(true);
            }
          } else {
            setToastSeverity("error")
            setToastMessage("New password must contain an alphanumeric value");
            setOpenToast(true);
          }
        } else {
          setToastSeverity("error")
          setToastMessage("New password must contain at least one capital letter");
          setOpenToast(true);
        }
      } else {
        setToastSeverity("error")
        setToastMessage("Please fill all fields");
        setOpenToast(true);
      }
    } else {
      setToastSeverity("error")
      setToastMessage("Confirm checkbox is not selected");
      setOpenToast(true);
    }

  }

  const [primaryColorCode, setPrimaryColorCode] = useState("#000000");
  const [secondaryColorCode, setSecondaryColorCode] = useState("#000000");
  const [lastUpdatedByForColor, setLastUpdatedByForColor] = useState("");
  const [lastUpdatedForColor, setLastUpdatedForColor] = useState(null);
  const [colorSchemeId, setColorSchemeId] = useState(null);
  const [name, setName] = useState("");

  const handleColorSubmit = async (event) => {
    event.preventDefault();
    if (name.length > 0) {
      const info = {
        colorSchemeId: colorSchemeId,
        primaryColor: primaryColorCode,
        secondaryColor: secondaryColorCode,
        lastUpdatedBy: name,
        lastUpdated: new Date(),
      };
      try {
        const response = await axios.put(process.env.REACT_APP_API + "Colors/updateColors", info);
        setToastSeverity("success")
        setToastMessage("Colors changed successfully");
        setOpenToast(true);
        await getColors();
        setName(null);
      } catch (error) {
        console.error(error);
        setToastSeverity("error")
        setToastMessage("There was an error!", error);
        setOpenToast(true);
      }
    } else {
      setToastSeverity("error")
      setToastMessage("Name required");
      setOpenToast(true);
    }
  };

  const [showHideEmailPassword, setShowHideEmailPassword] = useState(false);
  const [showHideCurrentPassword, setShowHideCurrentPassword] = useState(false);
  const [showHideNewPassword, setShowHideNewPassword] = useState(false);

  const onShowHideEmailPassword = () => {
    setShowHideEmailPassword(!showHideEmailPassword);
    var passwordInput = document.getElementById("adminEmailPassword");
    if (!showHideEmailPassword) {
      passwordInput.type = "text";
    } else if (showHideEmailPassword) {
      passwordInput.type = "password";
    }
  }

  const onShowHideCurrentPassword = () => {
    setShowHideCurrentPassword(!showHideCurrentPassword);
    var passwordInput = document.getElementById("passwordCurrent");
    if (!showHideCurrentPassword) {
      passwordInput.type = "text";
    } else if (showHideCurrentPassword) {
      passwordInput.type = "password";
    }
  }

  const onShowHideNewPassword = () => {
    setShowHideNewPassword(!showHideNewPassword);
    var passwordInput = document.getElementById("passwordNew");
    if (!showHideNewPassword) {
      passwordInput.type = "text";
    } else if (showHideNewPassword) {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="page-container">
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      <Header />
      <div className="d-flex w-100 h-100">
        <Sidebar />
        <div className="page-inner w-100">
          <div className="page-title-row mb-5">
            <span className="page-title">Change Login Details</span>
          </div>

          <Tabs
            defaultActiveKey="adminEmail"
          >
            <Tab eventKey="adminEmail" title="Change Admin Email">
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-items-end tab-col-mob">
                  <span className="sub-title">Change Admin Email</span>
                  <div className="flex-column-end center-col-mob">
                    <span className="edited-details">
                      Last Edited : <b>{lastUpdatedBy}</b>
                    </span>
                    <span className="edited-details">{lastUpdated}</span>
                  </div>
                </div>
                <hr className="w-100" />
                <Form className="d-flex flex-column align-items-start w-100 mb-4">
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      Current Email :
                    </Form.Label>
                    <Form.Control className="form-edit-input" value={currentAdminEmail} type="email" placeholder="Enter current email"
                      onChange={(e) => setCurrentAdminEmail(e.target.value)} />
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      New Email :
                    </Form.Label>
                    <Form.Control className="form-edit-input" value={newAdminEmail} type="email" placeholder="Enter new email"
                      onChange={(e) => setNewAdminEmail(e.target.value)} />
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      Password :
                    </Form.Label>
                    <Form.Control id="adminEmailPassword" className="form-edit-input password-input" value={adminEmailChangePassword} type="password" placeholder="Enter password"
                      onChange={(e) => setAdminEmailChangePassword(e.target.value)} />
                    <IconButton className="password-eye-black" onClick={onShowHideEmailPassword}>
                      {showHideEmailPassword && (<VisibilityOffIcon />)}
                      {!showHideEmailPassword && (<VisibilityIcon />)}
                    </IconButton>
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      Your Name :
                    </Form.Label>
                    <Form.Control className="form-edit-input" type="text" value={lastUpdatedBy} disabled={true} placeholder="Enter your name" />
                  </Form.Group>
                  <div className="d-flex w-100 align-items-center justify-content-between tab-col-mob">
                    <Form.Check
                      type="checkbox"
                      id="emailConfirm"
                      label="Confirm" onChange={() => {
                        setIsConfirmEmailChange(!isConfirmEmailChange);
                      }}
                    />
                    <Button
                      className="send-request-btn"
                      variant="contained" onClick={changeEmailRequest}
                    >
                      Send Request
                    </Button>
                  </div>
                </Form>
                <span className="description-label mt-4 mb-3">Instructions to change admin email</span>
                <span className="description-text mb-2">1. Fill in the form with your current emailv new admin email, password and
                  your name.
                </span>
                <span className="description-text mb-2">2. Tick the checkbox to confirm that you have verified and agree to the
                  provided details.
                </span>
                <span className="description-text mb-2">3. Send the reluest by clicking “Send Reluest” button.</span>
                <span className="description-text mb-2">4. Check your current email for a confirmation message.
                </span>
                <span className="description-text mb-2">5. Click the confirmation link in the email.
                </span>
                <span className="description-text mb-2">6. Check your new admin email for another email.
                </span>
                <span className="description-text mb-2">7. Click the confirmation link in the email.
                </span>
              </div>
            </Tab>
            <Tab eventKey="adminPassword" title="Change Admin Password">
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-items-end tab-col-mob">
                  <span className="sub-title">Change Admin Password</span>
                  <div className="flex-column-end center-col-mob">
                    <span className="edited-details">
                      Last Edited : <b>{lastUpdatedBy}</b>
                    </span>
                    <span className="edited-details">{lastUpdated}</span>
                  </div>
                </div>
                <hr className="w-100" />
                <Form className="d-flex flex-column align-items-start w-100 mb-4">
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      Current Password :
                    </Form.Label>
                    <Form.Control id="passwordCurrent" className="form-edit-input password-input" value={currentPassword} type="password" placeholder="Enter current password"
                      onChange={(e) => setCurrentPassword(e.target.value)} />
                    <IconButton className="password-eye-black" onClick={onShowHideCurrentPassword}>
                      {showHideCurrentPassword && (<VisibilityOffIcon />)}
                      {!showHideCurrentPassword && (<VisibilityIcon />)}
                    </IconButton>
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      New Password :
                    </Form.Label>
                    <Form.Control id="passwordNew" className="form-edit-input password-input" value={newPassword} type="password" placeholder="Enter current password"
                      onChange={(e) => setNewPassword(e.target.value)} />
                    <IconButton className="password-eye-black" onClick={onShowHideNewPassword}>
                      {showHideNewPassword && (<VisibilityOffIcon />)}
                      {!showHideNewPassword && (<VisibilityIcon />)}
                    </IconButton>
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      Confirm Password :
                    </Form.Label>
                    <Form.Control className="form-edit-input" value={confirmPassword} type="password" placeholder="Enter new password again"
                      onChange={(e) => setConfirmPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-center w-100 mb-4"
                  >
                    <Form.Label className="form-label me-2">
                      Your Name :
                    </Form.Label>
                    <Form.Control className="form-edit-input" type="text" value={lastUpdatedBy} disabled={true} placeholder="Enter your name" />
                  </Form.Group>
                  <div className="d-flex w-100 align-items-center justify-content-between tab-col-mob">
                    <Form.Check
                      type="checkbox"
                      id="passwordConfirm"
                      label="Confirm" onChange={() => {
                        setIsConfirmPasswordChange(!isConfirmPasswordChange);
                      }}
                    />
                    <Button
                      className="send-request-btn"
                      variant="contained" onClick={changePasswordRequest}
                    >
                      Send Request
                    </Button>
                  </div>
                </Form>
                <span className="description-label mt-4 mb-3">Instructions to change admin password</span>
                <span className="description-text mb-2">1. Fill in the form with your current password, new password, confirm new password and
                  your name.
                </span>
                <span className="description-text mb-2">2. Tick the checkbox to confirm that you have verified and agree to the
                  provided details.
                </span>
                <span className="description-text mb-2">3. Send the reluest by clicking “Send Reluest” button.</span>
                <span className="description-text mb-2">4. Check your admin email for a confirmation message to change the password.
                </span>
                <span className="description-text mb-2">5. Click the confirmation link in the email.
                </span>
              </div>
            </Tab>
            <Tab eventKey="colors" title="Change Colors">
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-items-end tab-col-mob">
                  <span className="sub-title">Change Colors</span>
                  <div className="flex-column-end center-col-mob">
                    <span className="edited-details">
                      Last Edited : <b>{lastUpdatedByForColor}</b>
                    </span>
                    <span className="edited-details">{lastUpdatedForColor}</span>
                  </div>
                </div>
                <hr className="w-100" />
                <div className="colors-grid">
                  <div className="flex-column-start">
                    <span className="sub-title">Primary Color</span>
                    <hr className="w-100" />
                    <span className="details">
                      Altering this color will update buttons, links, and other primary
                      components throughout the website.
                    </span>
                    <ChromePicker
                      className="color-picker"
                      color={primaryColorCode}
                      onChange={(e) => setPrimaryColorCode(e.hex)}
                    />
                  </div>
                  <div className="flex-column-start">
                    <span className="sub-title">Secondary Color</span>
                    <hr className="w-100" />
                    <span className="details">
                      Altering this color will update buttons, links, and other primary
                      components throughout the website.
                    </span>
                    <ChromePicker
                      className="color-picker"
                      color={secondaryColorCode}
                      onChange={(e) => setSecondaryColorCode(e.hex)}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 mt-5 footer-col-mob">
                  <div className="header-row">
                    <div className="flex-row-center">
                      <WarningIcon className="footer-icon" />
                      <span className="footer-details">
                        By saving this, all the previous details will be completely
                        deleted and new changes will be added. You should enter your name
                        before you save.
                      </span>
                    </div>
                    <div className="save-div">
                      <Form className="d-flex align-items-center footer-col-mob">
                        <Form.Group
                          className="d-flex align-items-center footer-start-col-mob"
                        >
                          <Form.Label className="footer-label">Name : </Form.Label>
                          <Form.Control
                            className="save-input"
                            type="text"
                            placeholder="Enter name"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>
                        <Button
                          className="save-btn"
                          variant="contained"
                          onClick={handleColorSubmit}
                        >
                          Save
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>

        </div>
      </div>

    </div>
  );
};

export default LoginDetails;
