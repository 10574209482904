import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Landing from "./pages/landing/Landing";
import NotFound from "./pages/general/NotFound";
import Locations from "./pages/locations/Locations";
import Colors from "./pages/colors/Colors";
import EditLocation from "./pages/locations/EditLocation";
import LoginDetails from "./pages/settings/LoginDetails";
import ConfirmPasswordChange from "./pages/settings/ConfirmPasswordChange";
import ConfirmChangeEmail from "./pages/settings/ConfirmChangeEmail";
import ChangeEmail from "./pages/settings/ChangeEmail";

function App() {
  let location = useLocation();
  return (
    <div className="App">
      <TransitionGroup>
        <CSSTransition key={location.pathname} classNames="fade" timeout={3000}>
          <Routes>
            <Route path="/" element={<Landing />}></Route>
            <Route path="/locations" element={<Locations />}></Route>
            <Route path="/edit-location/:id" element={<EditLocation />}></Route>
            <Route path="/colors" element={<Colors />}></Route>
            <Route path="/change-details" element={<LoginDetails />}></Route>
            <Route path="/change-password/:username/:currentPassword/:newPassword" element={<ConfirmPasswordChange />}></Route>
            <Route path="/change-email-confirmation/:username/:newEmail/:password" element={<ConfirmChangeEmail />}></Route>
            <Route path="/change-email/:username/:newEmail/:password" element={<ChangeEmail />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
