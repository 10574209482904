import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import slEmblem from "../images/emblem.png";
import profileImg from "../images/icons/profile.svg";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import axios from "axios";

const Header = (props) => {
  const [primaryColorCode, setPrimaryColorCode] = useState("#000000");
  const [secondaryColorCode, setSecondaryColorCode] = useState("#000000");

  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/locations");
  };

  const navigateToColors = () => {
    navigate("/colors");
  };

  const navigateToProfileSettings = () => {
    navigate("/change-details");
  };

  useEffect(() => {
    getColors();
  }, []);

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      setPrimaryColorCode(response.data[0].primaryColor);
      setSecondaryColorCode(response.data[0].secondaryColor);
      document.documentElement.style.setProperty(
        "--primary-color",
        response.data[0].primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        response.data[0].secondaryColor
      );
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  return (
    <div className="header-container">
      <div className="header-row">
        <div className="flex-row-center" onClick={navigateToHome}>
          <img className="header-emblem" src={slEmblem} alt="" />
          <div className="flex-column-start header-title-row">
            <span className="header-title">VR TOUR OF</span>
            <span className="header-title">PARLIAMENT OF SRI LANKA</span>
          </div>
        </div>
        <div className="settings-div">
          <div className="flex-row-center">
            <span className="header-desc">Change Color : </span>
            <div className="change-color-div">
              <div className="flex-column-start">
                <div className="primary-color-div"></div>
                <div className="secondary-color-div"></div>
              </div>
              <IconButton
                className="change-color-icon" onClick={navigateToProfileSettings}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <IconButton
                className="ms-2"
                onClick={navigateToProfileSettings}
              >
                <img src={profileImg} width="25" />
              </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
