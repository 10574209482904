import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { ChromePicker } from "react-color";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import Form from "react-bootstrap/Form";
import axios from "axios";

const Colors = () => {
  useEffect(() => {
    const token = sessionStorage.getItem("_auth");
    if (token && token != null) {
      getColors();
    } else {
      navigate("/");
    }
  }, []);

  const [primaryColorCode, setPrimaryColorCode] = useState("#000000");
  const [secondaryColorCode, setSecondaryColorCode] = useState("#000000");
  const [lastUpdatedBy, setLastUpdatedBy] = useState("");
  const [lastUpdated, setLastUpdated] = useState(null);
  const [colorSchemeId, setColorSchemeId] = useState(null);
  const [name, setName] = useState("");

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      setColorSchemeId(response.data[0].colorSchemeId);
      setPrimaryColorCode(response.data[0].primaryColor);
      setSecondaryColorCode(response.data[0].secondaryColor);
      setLastUpdatedBy(response.data[0].lastUpdatedBy);
      let formatDate = format(
        new Date(response.data[0].lastUpdated),
        "dd/MM/yyyy HH:mm:ss"
      );
      setLastUpdated(formatDate);
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name.length > 0) {
      const info = {
        colorSchemeId: colorSchemeId,
        primaryColor: primaryColorCode,
        secondaryColor: secondaryColorCode,
        lastUpdatedBy: name,
        lastUpdated: new Date(),
      };
      try {
        const response = await axios.put(process.env.REACT_APP_API + "Colors/updateColors", info);
        window.location.reload();
      } catch (error) {
        console.error(error);
        console.log("There was an error!", error);
      }
    } else {
      console.log("Name required");
    }
  };

  const navigate = useNavigate();

  return (
    <div className="page-container">
      <div className="page-inner">
        <div className="page-title-row">
          <span className="page-title">Change Colors</span>
          <div className="flex-column-end">
            <span className="edited-details">
              Last Edited : <b>{lastUpdatedBy}</b>
            </span>
            <span className="edited-details">{lastUpdated}</span>
          </div>
        </div>
        <div className="colors-grid">
          <div className="flex-column-start">
            <span className="sub-title">Primary Color</span>
            <hr className="w-100" />
            <span className="details">
              Altering this color will update buttons, links, and other primary
              components throughout the website.
            </span>
            <ChromePicker
              className="color-picker"
              color={primaryColorCode}
              onChange={(e) => setPrimaryColorCode(e.hex)}
            />
          </div>
          <div className="flex-column-start">
            <span className="sub-title">Secondary Color</span>
            <hr className="w-100" />
            <span className="details">
              Altering this color will update buttons, links, and other primary
              components throughout the website.
            </span>
            <ChromePicker
              className="color-picker"
              color={secondaryColorCode}
              onChange={(e) => setSecondaryColorCode(e.hex)}
            />
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div className="header-row">
          <div className="flex-row-center">
            <WarningIcon className="footer-icon" />
            <span className="footer-details">
              By saving this, all the previous details will be completely
              deleted and new changes will be added. You should enter your name
              before you save.
            </span>
          </div>
          <div className="save-div">
            <Form className="d-flex align-items-center">
              <Form.Group
                className="d-flex align-items-center"
                controlId="formName"
              >
                <Form.Label className="footer-label">Name : </Form.Label>
                <Form.Control
                  className="save-input"
                  type="text"
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Button
                className="save-btn"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colors;
