import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import slEmblem from "../../images/emblem.png";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Landing = () => {

  const [openToast, setOpenToast] = React.useState(false);
  const [toastSeverity, setToastSeverity] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showHidePassword, setShowHidePassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getColors();
    const token = sessionStorage.getItem("_auth");
    if (token && token != null) {
      navigate("/locations");
    } else {
    }
  }, []);

  const getColors = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "Colors/getColors");
      document.documentElement.style.setProperty(
        "--primary-color",
        response.data[0].primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        response.data[0].secondaryColor
      );
    } catch (error) {
      console.error(error);
      console.log("There was an error!", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const info = { userName: email, password: password };
    try {
      const response = await axios.post(process.env.REACT_APP_API + "authentication/login", info);
      sessionStorage.setItem("_auth", response.data.accessToken);
      sessionStorage.setItem("userName", response.data.userName);
      navigate("/locations");
    } catch (error) {
      console.error(error);
      setToastSeverity("error")
      setToastMessage("Username / Password is incorrect");
      setOpenToast(true);
    }
  };

  const onShowHidePassword = () => {
    setShowHidePassword(!showHidePassword);
    var passwordInput = document.getElementById("passwordInput");
    if (!showHidePassword) {
      passwordInput.type = "text";
    } else if (showHidePassword) {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="landing-bg">
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      <div className="landing-container">
        <div className="center-container">
          <div className="landing-center-container">
            <div className="flex-column-container">
              <img className="sl-emblem" src={slEmblem} alt="" />
              <span className="landing-heading">VR TOUR OF</span>
              <span className="landing-heading">PARLIAMENT OF SRI LANKA</span>
            </div>
            <div className="flex-column-container form-div">
              <Form className="d-flex flex-column">
                <Form.Group
                  className="d-flex flex-column"
                >
                  <Form.Control
                    className="login-input-control"
                    type="text"
                    placeholder="Username"
                    value={email}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <div className="d-flex align-items-center">
                    <Form.Control id="passwordInput"
                      className="login-input-control"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <IconButton className="password-eye" onClick={onShowHidePassword}>
                      {showHidePassword && (<VisibilityOffIcon />)}
                      {!showHidePassword && (<VisibilityIcon />)}
                    </IconButton>
                  </div>
                </Form.Group>
                <Button
                  type="submit"
                  className="login-btn"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
